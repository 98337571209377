<template>
  <div>
    <div class="row">
      <div class="col">
        <sports-filter ref="sidebar" :show-is-disabled="true" :show-sports="false" :showMarkets="false" />
        <data-table
          v-if="siteId"
          model="sport/SiteProvider"
          service-file="SiteProvidersService"
          :path="path()"
          :columns="columns()"
          :column-templates="columnTemplates()"
          :rowActionButtons="rowActions()"
          :show-create="false"
          :show-remove="false"
          :customActionButton="{
            action: showSorting,
            title: 'Sort providers',
            icon: 'uil uil-sort',
          }"
          :show-edit-modal="false"
          :customRowClick="customRowClickPathFunction"
          ref="list"
          :extraComponentHeight="sidebarHeight"
        />
      </div>
      <sortable-table
        ref="sortable"
        :table-fields="sortFields()"
        title="Sort enabled providers"
        service-file="SiteProvidersService"
        @refresh="$refs.list.search()"
      />
    </div>
  </div>
</template>

<script lang="jsx">
import BasicOptions from '@/mixins/BasicOptions.js'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import SiteProvidersService from '@services/SiteProvidersService.js'
import SportsFilter from '@molecules/sports/sports-filter.vue'
import SortableTable from '@molecules/sports/sortable-table.vue'

export default {
  created() {
    this.setTitle()
  },
  mounted() {
    this.sidebarHeight = this.$refs.sidebar.$el.clientHeight
  },
  data() {
    return {
      sidebarHeight: 0,
    }
  },
  computed: {
    siteId() {
      return this.$store.state.sidebar.siteId
    },
    showDisabled() {
      return this.$store.state.sidebar.showDisabled
    },
  },
  watch: {
    siteId() {
      this.applyFilters()
    },
    showDisabled() {
      this.applyFilters()
    },
  },
  components: { SportsFilter, SortableTable },
  methods: {
    path() {
      return `hercules/sports/site-providers?${this.buildQueryString(
        Object.assign({ with: 'provider', with_count: 'valid_links' }, this.getFilters())
      )}`
    },
    columns() {
      return [
        {
          field: 'provider.label',
          type: 'text',
          search_key: 'provider__name',
          order_key: 'provider__name',
          label: 'Provider',
          filterable: true,
        },
        { field: 'rating', type: 'text', filterable: true },
        { field: 'enabled', type: 'text', filterable: true },
        { field: 'main', type: 'text', filterable: true },
        { field: 'featured', type: 'text', filterable: true },
        { field: 'sort_order', type: 'text', filterable: true },
        { field: 'valid_links_count', type: 'text', label: 'Valid links', filterable: true },
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.list.isLoading = true
      let object = { id: id }
      object[field] = value
      SiteProvidersService.update(object)
        .then(() => {
          this.$refs.list.search()
          this.showSuccessMessage(`Column updated`)
        })
        .catch(() => {
          this.showErrorMessage('Failed to update tournament')
          this.$refs.list.isLoading = false
        })
    },
    columnTemplates() {
      const self = this
      return Object.assign(BasicOptions.activeTemplate(['enabled']), {
        affiliate_link: function (row) {
          return row.affiliate_link.substring(0, 20) + '..'
        },
        main: function (row) {
          return <EnabledActionColumn row={row} field={'main'} onColumnClick={self.actionColumnClick} />
        },
        featured: function (row) {
          return <EnabledActionColumn row={row} field={'featured'} onColumnClick={self.actionColumnClick} />
        },
        enabled: function (row) {
          return <EnabledActionColumn row={row} field={'enabled'} onColumnClick={self.actionColumnClick} />
        },
        valid_links_count: function (row) {
          if (row.enabled && row.valid_links_count === 0) {
            return (
              <span data-tooltip="Provider is enabled but has no valid links">
                {row.valid_links_count} <i class="uil uil-exclamation-triangle ml-1 text-danger" />
              </span>
            )
          }
          return <span>{row.valid_links_count}</span>
        },
      })
    },
    rowActions() {
      return [
        {
          action: this.sportsData,
          title: 'Sports',
          icon: 'uil uil-volleyball',
          class: 'btn-info',
        },
        {
          action: this.editLinks,
          title: 'Links',
          icon: 'uil uil-adjust-circle',
          class: 'btn-secondary',
        },
      ]
    },
    showSorting() {
      SiteProvidersService.get({
        site_id: this.siteId,
        orderBy: 'sort_order',
        ascending: 1,
        enabled: 1,
        with: 'provider,sport',
      })
        .then((response) => {
          if (response.data.success) {
            this.$refs.sortable.show(response.data.result)
          } else {
            this.showErrorMessage('Failed to fetch providers')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    sortFields() {
      return [
        { name: 'provider.label', label: 'Provider' },
        { name: 'enabled', label: 'Enabled' },
      ]
    },
    editLinks(row) {
      this.$router.push(`/sports-site-providers/${row.id}/links`)
    },
    sportsData(row) {
      this.$router.push(`/sports-site-providers/${row.id}/sports-data`)
    },
    getFilters() {
      let filters = {}
      if (this.siteId) filters['site_id'] = this.siteId
      if (this.showDisabled === false) filters['enabled'] = 1
      return filters
    },
    applyFilters() {
      this.emitter.emit('data-table.applyFilters', this.getFilters())
    },
    customRowClickPathFunction(click, row, ctrlClick) {
      const path = `/sports-site-providers/${row.id}/site-data`
      ctrlClick ? window.open(path, '_blank') : this.$router.push(path)
    },
  },
}
</script>
