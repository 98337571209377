<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close" v-if="showModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <div>
            <button class="btn btn-secondary" v-bind:class="{ loading: loading }" @click="close()">
              <i class="uil uil-times mr-2"></i> Close
            </button>
            <button class="btn btn-primary ml-4" v-bind:class="{ loading: loading }" @click="submit()">
              <i class="uil uil-check mr-2"></i> Save changes
            </button>
          </div>
        </div>
        <div class="modal-body">
          <table class="table">
            <thead>
              <tr>
                Sort order
              </tr>
              <tr v-for="(field, fieldIndex) in tableFields" :key="fieldIndex">
                {{
                  field.label
                }}
              </tr>
            </thead>
            <draggable v-model="rows" element="tbody" item-key="id">
              <template #item="{ element, index }">
                <tr :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td v-for="(field, fieldIndex) in tableFields" :key="fieldIndex">
                    {{ findInObject(field.name.split('.'), element) }}
                  </td>
                </tr>
              </template>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import draggable from 'vuedraggable'

export default {
  components: { BaseCheckbox, BaseInput, draggable },
  data() {
    return {
      loading: false,
      showModal: false,
      siteProvider: null,
      rows: [],
    }
  },
  props: {
    tableFields: {
      type: Array,
    },
    serviceFile: {
      type: String,
      default: () => null,
    },
    title: {
      type: String,
      default: 'Sorting table',
    },
  },
  computed: {
    serviceLoader() {
      return () => import(`@services/${this.serviceFile}.js`)
    },
  },
  methods: {
    show(data) {
      this.rows = data
      document.getElementById('modal').appendChild(this.$el)
      this.showModal = true
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.showModal = true
      this.$emit('closed')
    },
    async submit() {
      this.loading = true
      const service = await this.serviceLoader()
      service.default
        .updateSortOrder({ records: this.rows })
        .then((response) => {
          if (response.data.success) {
            this.showSuccessMessage('Order saved')
            this.$emit('refresh')
            this.close()
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table td {
  padding: 6px 24px;
}
</style>
